import { graphql, useStaticQuery } from 'gatsby';

export const useAboutQuery = () => {
  const { wordpressPage } = useStaticQuery(
    graphql`
      query AboutQuery {
        wordpressPage(wordpress_id: { eq: 2 }) {
          acf {
            img {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            pl {
              content
            }
            en {
              content
            }
          }
        }
      }
    `,
  );
  return wordpressPage.acf;
};
